import { ReactElement, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import { Row, Col, Container } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import './upload-photo.style.scss';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { photoUploadRoutine } from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import ImageUpload, { ImageUploadRef } from './upload';
import pillBottleImg from 'assets/images/pill-bottle.png';
import { setDrugLookupStatus } from 'state/drug/drug.reducers';
import { setIsPhotoUploaded } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPhotoProcessedSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { photoComponentDisabledSelector, uploadPhotoImgSrcSelector } from 'state/upload-photo/upload-photo.selectors';
import { setImageSrc, setIsPhotoComponentEnabled } from 'state/upload-photo/upload-photo.reducers';

const UploadPhoto = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setPrescriptionFlowType } = useAddTransferPrescription();
    const isPhotoProcessed = useSelector(addTransferPhotoProcessedSelector);
    const imageSrc = useSelector(uploadPhotoImgSrcSelector);
    const isPhotoComponentDisabled = useSelector(photoComponentDisabledSelector);

    const [imageIsUploaded, setImageIsUploaded] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [isProcessingImage, setIsProcesingImage] = useState(false);
    const [showMsgInfo, setShowMsgInfo] = useState(true);
    const [cropper, setCropper] = useState<any>();
    const imageUploadComponentRef = useRef<ImageUploadRef | null>(null);

    const handleImageSelect = (e: any) => {
        dispatch(setImageSrc(URL.createObjectURL(e.target.files[0])));
    };
    const cropperRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        dispatch(setImageSrc(''));
        setIsProcesingImage(false);
        setUploadError(false);
        dispatch(setIsPhotoUploaded({ isPhotoUploaded: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitClick = () => {
        if (typeof cropper !== 'undefined') {
            setUploadError(false);
            setIsProcesingImage(true);
            dispatch(
                photoUploadRoutine.trigger({
                    imageUrl: cropper.getCroppedCanvas().toDataURL(),
                    onSuccess: () => {
                        setIsProcesingImage(false);
                        dispatch(setDrugLookupStatus('FETCHED_PHOTO'));
                        dispatch(setIsPhotoUploaded({ isPhotoUploaded: false }));
                    },
                    onFailure: () => {
                        setUploadError(true);
                        setIsProcesingImage(false);
                    }
                })
            );
        }
    };
    const changePhotoClick = () => {
        dispatch(setImageSrc(''));
        imageUploadComponentRef.current?.uploadClick();
    };

    const dismissPhotoComponent = () => {
        dispatch(setImageSrc(''));
        dispatch(setIsPhotoComponentEnabled(true));
    };

    const handleBackClick = () => {
        setPrescriptionFlowType({ flowType: 'Transfer' });
        setUploadError(false);
    };

    useEffect(() => {
        setUploadError(false);
        setImageIsUploaded(imageSrc.length > 0);
        dispatch(setIsPhotoUploaded({ isPhotoUploaded: imageSrc.length > 0 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSrc]);

    const MessageInfo = () => {
        return (
            <div className="upload-photo--message-wrapper">
                <div className="message-wrapper">
                    {t('pages.uploadPrescriptionPhoto.messageFilled')}
                    <button className="close" type="button" onClick={() => setShowMsgInfo(!showMsgInfo)}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            {!isPhotoComponentDisabled ? (
                <Col className="p-md-0">
                    {isPhotoProcessed ? (
                        <>{showMsgInfo && <MessageInfo />}</>
                    ) : (
                        <>
                            <Row className="upload-photo--wrapper d-flex justify-content-center mb-4">
                                {!imageIsUploaded && (
                                    <>
                                        <Container className="justify-content-center text-center pb-3">
                                            <h2 className="">
                                                {t('pages.uploadPrescriptionPhoto.inASnapInstructions.addPhoto')}
                                            </h2>
                                        </Container>
                                        <Col
                                            xs={12}
                                            md={12}
                                            lg={5}
                                            className="text-center d-flex align-items-center justify-content-center p-0"
                                        >
                                            <img src={pillBottleImg} alt="" />
                                        </Col>
                                        <Col xs={12} md={12} lg={7} className="content-container">
                                            <ul>
                                                <li>
                                                    <div className="h4">
                                                        {t(
                                                            'pages.uploadPrescriptionPhoto.inASnapInstructions.firstBullet.text'
                                                        )}
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div className="h4">
                                                                {t(
                                                                    'pages.uploadPrescriptionPhoto.inASnapInstructions.firstBullet.firstSubBullet'
                                                                )}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="h4">
                                                                {t(
                                                                    'pages.uploadPrescriptionPhoto.inASnapInstructions.firstBullet.secondSubBullet'
                                                                )}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <div className="h4">
                                                        {t(
                                                            'pages.uploadPrescriptionPhoto.inASnapInstructions.secondBullet'
                                                        )}
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </>
                                )}
                                {imageIsUploaded && (
                                    <>
                                        <Container className="upload-photo--helper pb-4">
                                            <div className="eyebrown">{t('pages.uploadPrescriptionPhoto.preview')}</div>
                                            <p>{t('pages.uploadPrescriptionPhoto.confirmImage')}</p>
                                        </Container>
                                        <Col className="crop-container">
                                            <Cropper
                                                enable={false}
                                                src={imageSrc}
                                                guides={false}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                autoCropArea={1}
                                                autoCrop={true}
                                                background={false}
                                                movable={true}
                                                rotatable={true}
                                                ref={cropperRef}
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row className={imageIsUploaded ? 'mb-2' : ''}>
                                {imageIsUploaded && (
                                    <>
                                        {uploadError && (
                                            <Col sm={8} className="offset-md-2">
                                                <div className="crop-help text-warning">
                                                    <Button
                                                        variant="text"
                                                        label={t('pages.uploadPrescriptionPhoto.uploadError')}
                                                        type="submit"
                                                        className="my-2 mx-md-2 d-block sm-full d-sm-inline text-warning font-weight-bolder"
                                                        onClick={handleBackClick}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>
                            <Row className="justify-content-center mt-2 mb-4">
                                <Col className="col-12 text-center">
                                    {imageIsUploaded && (
                                        <>
                                            <Row className="d-flex justify-content-center">
                                                <Button
                                                    variant="outline-primary"
                                                    label={t('pages.uploadPrescriptionPhoto.useDifferentPhotoButton')}
                                                    type="submit"
                                                    className="my-2 mx-md-2 d-block sm-full d-sm-inline mx-3 font-weight-bolder"
                                                    onClick={changePhotoClick}
                                                />
                                                <Button
                                                    variant="primary"
                                                    label={t('pages.uploadPrescriptionPhoto.submitButton')}
                                                    type="submit"
                                                    className="my-2 mx-md-2 d-block sm-full d-sm-inline mx-3 font-weight-bolder"
                                                    isBusy={isProcessingImage}
                                                    async
                                                    onClick={handleSubmitClick}
                                                />
                                            </Row>
                                            <Row className="d-flex justify-content-center">
                                                <Button
                                                    variant="link"
                                                    label={t('pages.uploadPrescriptionPhoto.useDetailsManually')}
                                                    type="submit"
                                                    className="my-2 mx-md-2 d-block sm-full d-sm-inline btn-underline"
                                                    onClick={dismissPhotoComponent}
                                                />
                                            </Row>
                                        </>
                                    )}
                                    <ImageUpload
                                        onImageChange={handleImageSelect}
                                        showUploadButton={imageSrc ? true : false}
                                        uploadButtonLabel={t('pages.uploadPrescriptionPhoto.addPhotoButton')}
                                        ref={imageUploadComponentRef}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            ) : (
                <></>
            )}
        </>
    );
};

export default UploadPhoto;
