import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PrescriptionLayoutFormWrapperProps } from 'components/prescription-layout-form-wrapper/prescription-layout-form-wrapper.props';
import './prescription-layout-form-wrapper.style.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    addTransferPrescriptionIsPhotoUploadedSelector,
    addTransferPrescriptionStepSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import {
    setShowAddressStep,
    setShowPaymentStep,
    setStep
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import { accountFetchHealthConditionsRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { setLastPharmacySearchFormValues } from 'state/pharmacy/pharmacy.reducer';
import { setImageSrc, setIsPhotoComponentEnabled } from 'state/upload-photo/upload-photo.reducers';
import { useGlobalLink } from 'hooks/useGlobalLink';
import UploadPhoto from 'components/upload-photo';
import Button from 'ui-kit/button/button';

const PrescriptionLayoutFormWrapper = ({
    children,
    className,
    eyebrowText,
    flowType,
    steps
}: PrescriptionLayoutFormWrapperProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const globalLink = useGlobalLink();
    const currentBackgroundImage = steps[0].backgroundImage;
    const currentStepState = useSelector(addTransferPrescriptionStepSelector);
    const isPhotoUploaded = useSelector(addTransferPrescriptionIsPhotoUploadedSelector);

    useEffect(() => {
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true }));
        dispatch(accountGetAllCreditCardsRoutine.trigger());
        dispatch(accountFetchHealthConditionsRoutine.trigger());
        dispatch(setShowPaymentStep({ showPaymentStep: false }));
        dispatch(setShowAddressStep({ showAddressStep: false }));
        dispatch(setStep({ currentStep: 0 }));

        // clear out any previous pharmacy search info
        dispatch(setLastPharmacySearchFormValues(undefined));
        dispatch(setIsPhotoComponentEnabled(false));
    }, []);

    useEffect(() => {
        const flowName = 'Transfer Rx';
        if (steps[currentStepState]) {
            // set global link properties to track form abandonment
            globalLink.setFlowName(flowName);
            globalLink.setStepName(steps[currentStepState].dataGAFormStepName ?? '');
            globalLink.setFormName(steps[currentStepState].dataGAFormName ?? '');
        }
    }, [currentStepState, globalLink, steps, t]);

    const resetImageUpload = () => {
        dispatch(setImageSrc(''));
    };

    const classes = classNames('prescription-layout-form-wrapper', className);

    return (
        <WorkflowLayout className="prescription" backgroundImage={currentBackgroundImage}>
            <div className={classes}>
                <Container fluid>
                    <Row
                        className={classNames('prescription-layout-form-wrapper-header d-flex flex-column', {
                            'back-button-wrapper': isPhotoUploaded
                        })}
                    >
                        {isPhotoUploaded && (
                            <div className="back-button">
                                <Button
                                    chevron="left"
                                    label={t('prescriptionLayout.back')}
                                    onClick={resetImageUpload}
                                    type="button"
                                    variant="text-blue"
                                />
                            </div>
                        )}
                        <Col className="d-flex flex-column justify-content-center align-items-center col-12 p-0">
                            {eyebrowText && <div className="h5">{eyebrowText}</div>}
                            {steps.map((step, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {currentStepState === index && <h1 className="h2">{step.title}</h1>}
                                    </React.Fragment>
                                );
                            })}
                            <div className="spacer" />
                            <UploadPhoto />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="prescription-layout-form-wrapper-content">
                            {steps.map((step, index) => (
                                <React.Fragment key={index}>
                                    {currentStepState === index && <div>{step.step}</div>}
                                </React.Fragment>
                            ))}
                        </Col>
                    </Row>
                </Container>
                {children}
            </div>
        </WorkflowLayout>
    );
};

export default PrescriptionLayoutFormWrapper;
