import React, { useState, useEffect, forwardRef } from 'react';
import { navigate } from 'gatsby';
import { USE_STATIC_FORMULARIES, ENABLE_PACKAGE_OPTIONS } from 'gatsby-env-variables';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import {
    addTransferPrescriptionFlowTypeSelector,
    addTransferPrescriptionSelector,
    addTransferPrescriptionDrugDetailsSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { accountHasInsuranceSelector } from 'state/account/account.selectors';
import {
    setDrugDetailValues,
    setFlowType,
    PackageOption
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { PrescriptionInfoFormProps } from './prescription-info-form.props';
import { PrescriptionTypes } from 'types/prescription';
import { NEW_PRESCRIPTION_SCHEMA, TRANSFER_PRESCRIPTION_SCHEMA } from 'schema/prescription.schema';

import DrugLookupForm, { extractPackageOptions } from 'components/drug-lookup-form/drug-lookup-form.component';
import { extractStrengths } from 'components/drug-lookup-form/drug-lookup-form.component';

import storageHelper from 'util/storageHelper';

import { drugLookupSelector, drugSelector } from 'state/drug/drug.selectors';
import { drugLookupRoutine, drugDetailsLookupRoutine, drugFormLookupRoutine } from 'state/drug/drug.routines';
import { setDrugLookupStatus, resetDrugLookupResults } from 'state/drug/drug.reducers';
import { DosageFormsObjectPayload, DrugLookupObjectPayload } from 'state/drug/drug.services';
import {
    DrugOption,
    DrugStrengths,
    StrengthOption
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';

import './prescription-info-form.style.scss';
import { setIsPastPrescriberFlow } from 'state/physician/physician.reducer';
import { setIsPastPharmacyFlow } from 'state/pharmacy/pharmacy.reducer';

const PrescriptionInfoForm = forwardRef(({ className, handleFormSubmit }: PrescriptionInfoFormProps, ref) => {
    const classes = classNames(className);
    const { t } = useTranslation();
    const formName = 'PrescriptionInfoForm';
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const currentFlowType = useSelector(addTransferPrescriptionFlowTypeSelector);
    const requestFormValues = useSelector(addTransferPrescriptionDrugDetailsSelector);
    const dispatch = useDispatch();
    const [formError, setFormError] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);
    const [isControlled, setIsControlled] = useState(false);
    const [isInTransferFlow, setIsInTransferFlow] = useState(false);
    const [isInRequestFlow, setIsInRequestFlow] = useState(false);
    const [isInSelectedDrugFlow, setIsInSelectedDrugFlow] = useState(false);
    const { drugLookupStatus, drugDetails } = useSelector(drugSelector);
    const drugs = useSelector(drugLookupSelector);

    // Check to see if there is a pre-selected drug (from a drug landing page or the get the birdi price page).
    const selectedDrug = storageHelper.session.getSelectedDrug();

    // Reset the drug lookup status on the very first form load.
    useEffect(() => {
        dispatch(setDrugLookupStatus('IDLE'));
        dispatch(resetDrugLookupResults());

        const selectedFlow = storageHelper.session.getPrescriptionFlowType();
        storageHelper.session.removeSelectedDrug();
        storageHelper.session.removePrescriptionFlowType();

        // If the user was not redirected from a landing page, or if they did not
        // manually kick off a new/transfer flow from the medicine cabinet, then
        // redirect back to the medicine cabinet.
        if (currentFlowType === '' && selectedFlow === null) {
            navigate('/secure/medicine-cabinet');
        }

        if (selectedDrug?.drugName !== '') {
            setIsInSelectedDrugFlow(true);
        }

        // If a selected flow has been specified, set it now.
        if (selectedFlow) {
            const submittedPrescribers = storageHelper.session.getSubmittedPrescribers();
            dispatch(setIsPastPrescriberFlow(submittedPrescribers?.length > 0 ? true : false));
            const getSubmittedPharmacies = storageHelper.session.getSubmittedPharmacies();
            dispatch(setIsPastPharmacyFlow(getSubmittedPharmacies?.length > 0 ? true : false));
            dispatch(setFlowType({ flowType: selectedFlow }));
        }
    }, []);

    // Update the drug forms dropdown after drug details have been fetched.
    useEffect(() => {
        // If we are in the middle of a transfer flow, do nothing.
        if (isInTransferFlow === true) {
            return;
        }

        // If this is a brand new flow, we shouldn't do anything until the status
        // is set back to IDLE, which happens in the previous useEffect.
        if (!isInRequestFlow && drugLookupStatus !== 'IDLE') return;

        // If drug details have been fetched, update the drugforms dropdown.
        switch (drugLookupStatus) {
            case 'FETCHED_DETAILS':
                updateDrugForms();
                break;
            default:
            // Do nothing.
        }
    }, [drugLookupStatus]);

    // Handle the form submit.
    const handleSubmit = (values: Partial<PrescriptionTypes>, actions: FormikHelpers<Partial<PrescriptionTypes>>) => {
        const selectedStrength = requestFormValues.drugStrengthOptions.find(
            (strength) => strength.value === values.strength
        );
        if (selectedStrength) {
            if (selectedStrength.drugCode !== values.drugCode) {
                // This prevents a "build-only" warning, where the drugCode cannot be updated
                // when it hasn't changed. DRX-742
                values.drugCode = selectedStrength.drugCode;
            }
        }

        // Pass the setSubmitting method to the submit function so that the button
        // loading state can be reset once the pricing modal is visible.
        handleFormSubmit(values as PrescriptionTypes, actions.setSubmitting, isInSelectedDrugFlow);
    };

    // Reset the form.
    const handleReset = (form: any) => {
        setIsControlled(false);
        setIsFormValid(true);
        setIsInTransferFlow(false);
        setIsInRequestFlow(true);
        setIsInSelectedDrugFlow(false);
        setFormError('');
        dispatch(setDrugLookupStatus('IDLE'));
    };

    // Update the drugforms dropdown.
    // This is called from useEffect when the drugLookupStatus indicates that
    // drug details have been fetched.
    const updateDrugForms = () => {
        const drugForms = drugDetails.dosageForms.map((item: DosageFormsObjectPayload) => {
            return {
                key: item.dosageForm,
                label: item.dosageForm,
                value: item.dosageForm,
                strengths: item.strengths,
                gpi: item.gpi, // DRX-1056 enhancement
                isUoU: item.isUoU // DRX-1056 enhancement
            };
        });

        // If there is only one drug form, go ahead and populate the drug strengths
        // as well.
        let drugStrengths: StrengthOption[] = [];
        if (drugForms.length === 1) {
            drugStrengths = extractStrengths(drugForms[0].strengths);
        }

        // DRX-1056 enhancement
        let pkgOptions = [];
        if (drugStrengths.length === 1) {
            pkgOptions = drugStrengths[0].packageOptions ? extractPackageOptions(drugStrengths[0]) : [];
        }
        dispatch(
            setDrugDetailValues({
                drugFormOptions: drugForms,
                drugStrengthOptions: drugStrengths,
                drugPackageOptions: pkgOptions // DRX-1056 enhancement
            })
        );
    };

    // Update the drugForm and strength dropdowns after a transfer match is found.
    const updateDrugDetailsTransfer = (formik: any) => {
        const drugData = drugDetails;

        // Set the drugName field.
        formik.setFieldValue('drugName', drugData.drugName);
        formik.setFieldValue('drugDisplayName', drugData.drugName);

        // Get the drugForm and strength from state (these come from the photo upload process)
        const drugForm = transferPrescriptionState.DrugForm ? transferPrescriptionState.DrugForm : '';
        const strength = transferPrescriptionState.Strength ? transferPrescriptionState.Strength : '';

        let drugForms: any[] = [];
        let drugStrengths: any[] = [];
        let pkgOptions: any[] = [];

        drugForms = drugData.dosageForms.map((item: DosageFormsObjectPayload) => {
            return { key: item.dosageForm, label: item.dosageForm, value: item.dosageForm, strengths: item.strengths };
        });

        drugForms.forEach((item: DrugOption, index: number) => {
            if (item.value.toLowerCase().replace(' ', '') === drugForm.toLowerCase().replace(' ', '')) {
                // If there is a match on the drugFrom from state and one of the
                // valid values from the drug lookup API, then set the value in the form.
                formik.setFieldValue('drugForm', item.value);

                // Find the related strengths.
                drugStrengths = drugData.dosageForms[index].strengths.map((strength: DrugStrengths) => {
                    if (strength.fullStrength.trim() === '') {
                        return {
                            key: 'N/A',
                            label: 'N/A',
                            value: 'N/A',
                            drugCode: '',
                            packageOptions: []
                        };
                    } else {
                        return {
                            key: strength.fullStrength,
                            label: strength.fullStrength,
                            value: strength.fullStrength,
                            drugCode: strength.gpi,
                            packageOptions: strength.packageOptions
                        };
                    }
                });

                drugStrengths.forEach((item: StrengthOption) => {
                    if (item.value.toLowerCase().replace(' ', '') === strength.toLowerCase().replace(' ', '')) {
                        // Same as above. Set the strength field value if there
                        // is a match.
                        formik.setFieldValue('strength', item.value);

                        // DRX-1056 enhancement
                        pkgOptions = extractPackageOptions(item);
                    }
                });
            }
        });

        pkgOptions.forEach((pkg: PackageOption) => {
            if (pkg.value === transferPrescriptionState.NDC) {
                formik.setFieldValue('ndc', pkg.value);
            }
        });

        // Update the form dropdown options.
        dispatch(
            setDrugDetailValues({
                drugFormOptions: drugForms,
                drugStrengthOptions: drugStrengths,
                drugPackageOptions: pkgOptions // DRX-1056 enhancement
            })
        );

        // Set isInRequestFlow to true so that we don't end up triggering another
        // lookup the next time the drug name changes.
        setIsInRequestFlow(true);

        // If the quantity was passed down in selectedDrug, then set it now.
        // We **could** populate this value via formik initialValues, but that
        // would require setting enableReinitialize={true}, and would also mean
        // that the quantity gets populated before the dropdowns, which is just
        // a little bit awkward.
        if (transferPrescriptionState.Qty !== '') {
            formik.setFieldValue('qty', transferPrescriptionState.Qty);
        }
    };

    // Compare the drug name provided by the transfer flow against the array of
    // potential matches returned by the drug lookup, and see if any of them match.
    const getDrugMatches = (drugName: string, drugGenericProductCode: string, form: any) => {
        if (drugs.length === 0) {
            setFormError(t('prescriptionInfoForm.errors.drugNotFound'));
            setIsFormValid(false);
        } else {
            // Loop through each of the matches to find an exact
            // match.
            const matches = drugs;
            let match: any;

            // DRX-914: Added the test for `brandDrugName` being empty, so that we find the correct drug,
            // and don't substitute a brand drug selection. The image upload payload does not include a
            // brand drug name.
            // DRX-957: Because drugName is no longer unique, we need to match the "genericProductCode" if passed in,
            // which is the case when coming from the Get The Birdi Price pages.
            matches.forEach((item: DrugLookupObjectPayload) => {
                if (
                    item.onSaleDrugName.toLowerCase().trim() === drugName.toLowerCase().trim() &&
                    ((drugGenericProductCode !== '' && item.genericProductCode === drugGenericProductCode) ||
                        (drugGenericProductCode === '' && item.brandDrugName === ''))
                ) {
                    match = item;
                }
            });

            // If there's a match, check to see if it's a controlled substance.
            if (match) {
                if (match.deaClassCode !== 'NA') {
                    setIsControlled(true);
                    form.setFieldValue('drugName', match.onSaleDrugName);
                    form.setFieldValue('drugForm', '');
                    form.setFieldValue('strength', '');

                    // The field must be touched in order for the isControlled
                    // error to display.
                    form.setFieldTouched('drugName');
                } else {
                    if (ENABLE_PACKAGE_OPTIONS) {
                        dispatch(
                            drugFormLookupRoutine.trigger({
                                drugName: match.onSaleDrugName,
                                gpi: match.genericProductCode
                            })
                        );
                    } else {
                        dispatch(
                            drugDetailsLookupRoutine.trigger({
                                drugName: match.onSaleDrugName,
                                gpi: match.genericProductCode
                            })
                        );
                    }
                }
            } else {
                setFormError(t('prescriptionInfoForm.errors.drugNotFound'));
                setIsFormValid(false);
            }
        }
    };

    return (
        <div className={classes}>
            {/* TODO: Is there a more standard way to display an error here? */}
            {formError && (
                <Row>
                    <Col>
                        <p className="prescription-error">{formError}</p>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Formik<Partial<PrescriptionTypes>>
                        innerRef={ref as React.MutableRefObject<FormikProps<Partial<PrescriptionTypes>>>}
                        onSubmit={handleSubmit}
                        validateOnChange={true}
                        validateOnBlur={false}
                        validationSchema={() => {
                            return currentFlowType === 'New'
                                ? NEW_PRESCRIPTION_SCHEMA(isControlled)
                                : TRANSFER_PRESCRIPTION_SCHEMA(isControlled);
                        }}
                        initialValues={{
                            drugName: selectedDrug.drugName
                                ? selectedDrug.drugName
                                : transferPrescriptionState.DrugName?.length
                                ? transferPrescriptionState.DrugName
                                : '',
                            drugDisplayName: selectedDrug.drugDisplayName
                                ? selectedDrug.drugDisplayName
                                : transferPrescriptionState.DrugDisplayName?.length
                                ? transferPrescriptionState.DrugDisplayName
                                : '',
                            drugForm: selectedDrug.drugForm
                                ? selectedDrug.drugForm
                                : transferPrescriptionState.DrugForm
                                ? transferPrescriptionState.DrugForm
                                : '',
                            strength: selectedDrug.strength
                                ? selectedDrug.strength
                                : transferPrescriptionState.Strength
                                ? transferPrescriptionState.Strength
                                : '',
                            qty: selectedDrug.qty
                                ? selectedDrug.qty
                                : transferPrescriptionState.Qty
                                ? transferPrescriptionState.Qty
                                : '',
                            rxNumber: selectedDrug.rxNumber
                                ? selectedDrug.rxNumber
                                : transferPrescriptionState.RxNumber
                                ? transferPrescriptionState.RxNumber
                                : '',
                            imageUrl: transferPrescriptionState.ImageExtractedData
                                ? transferPrescriptionState.ImageExtractedData
                                : '',
                            gpi: selectedDrug.gpi
                                ? selectedDrug.gpi
                                : transferPrescriptionState.GPI
                                ? transferPrescriptionState.GPI
                                : '',
                            ndc: selectedDrug.ndc
                                ? selectedDrug.ndc
                                : transferPrescriptionState.NDC
                                ? transferPrescriptionState.NDC
                                : '',
                            genericProductCode: selectedDrug.genericProductCode
                                ? selectedDrug.genericProductCode
                                : transferPrescriptionState.GenericProductCode
                                ? transferPrescriptionState.GenericProductCode
                                : '',
                            packageDisplay: selectedDrug.packageDisplay
                                ? selectedDrug.packageDisplay
                                : transferPrescriptionState.PackageDisplay
                                ? transferPrescriptionState.PackageDisplay
                                : '',
                            strengthWithPackage: selectedDrug.strengthWithPackage
                                ? selectedDrug.strengthWithPackage
                                : transferPrescriptionState.StrengthWithPackage
                                ? transferPrescriptionState.StrengthWithPackage
                                : ''
                        }}
                    >
                        {function RenderForm(formik: any) {
                            // All of this code is in the useEffect for the form itself because we
                            // at least need access to the formik bag in order to be able to update
                            // the drugForm and strength field values after trying to match against
                            // the corresponding transfer values.
                            useEffect(() => {
                                if ((currentFlowType === 'New' && !isInSelectedDrugFlow) || isInRequestFlow) return;

                                // If this is a brand new flow, we shouldn't do anything until the status
                                // is set back to IDLE, which happens in the previous useEffect.
                                // if (!isInTransferFlow && drugLookupStatus !== 'IDLE') return;

                                const drugName = transferPrescriptionState.DrugName?.length
                                    ? transferPrescriptionState.DrugName
                                    : '';
                                const drugGenericProductCode = transferPrescriptionState.GenericProductCode?.length
                                    ? transferPrescriptionState.GenericProductCode
                                    : '';

                                // If there is no drugname, then go no further.
                                if (!drugName) return;

                                setIsInTransferFlow(true);

                                switch (drugLookupStatus) {
                                    case 'FETCHED_PHOTO':
                                    case 'IDLE': {
                                        if (!USE_STATIC_FORMULARIES) {
                                            // Update the lookup status.
                                            dispatch(setDrugLookupStatus('BUSY'));
                                            // Lookup the drug.
                                            // Once the dispatch is complete, drugLookupStatus will be updated.
                                            dispatch(
                                                drugLookupRoutine.trigger({
                                                    drugName: drugName,
                                                    onFailure: () => {
                                                        setFormError(t('prescriptionInfoForm.errors.drugNotFound'));
                                                        setIsFormValid(false);

                                                        dispatch(setDrugLookupStatus('FAILED'));
                                                        getDrugMatches(drugName, drugGenericProductCode, formik);
                                                    }
                                                })
                                            );
                                        } else {
                                            getDrugMatches(drugName, drugGenericProductCode, formik);
                                        }
                                        break;
                                    }
                                    case 'FETCHED_DRUGS':
                                        // Get drug matches.
                                        getDrugMatches(drugName, drugGenericProductCode, formik);

                                        // Update the lookup status.
                                        dispatch(setDrugLookupStatus('BUSY'));
                                        break;
                                    case 'FETCHED_DETAILS':
                                        updateDrugDetailsTransfer(formik);

                                        // Update the lookup status.
                                        dispatch(setDrugLookupStatus('BUSY'));
                                        break;
                                    default:
                                    // Do nothing.
                                }
                            }, [drugLookupStatus, isInSelectedDrugFlow]);

                            useEffect(() => {
                                formik.validateField('drugName');
                            }, [isControlled]);

                            return (
                                <DrugLookupForm
                                    isDrugNameEditable={true}
                                    formName={formName}
                                    formik={formik}
                                    isFormValid={isFormValid}
                                    isControlled={isControlled}
                                    accountHasInsurance={accountHasInsurance}
                                    currentFlowType={currentFlowType}
                                    setIsControlled={setIsControlled}
                                    setIsFormValid={setIsFormValid}
                                    formError={formError}
                                    setFormError={setFormError}
                                    handleReset={handleReset}
                                />
                            );
                        }}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
});

export default PrescriptionInfoForm;
