import { Formik, FormikProps } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Text from 'ui-kit/text/text';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import './pharmacy-lookup.style.scss';
import PharmacySearchForm from './pharmacy-search-form-component';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPharmacySearchFlow } from 'state/pharmacy/pharmacy.reducer';
import { ENABLE_PHARMACY_SEARCH } from 'gatsby-env-variables';
import { pharmacySearchFlowSelector } from 'state/pharmacy/pharmacy.selector';
import {
    PrescriptionPharmacyPayload,
    savePrescriptionPharmacy
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { formatPhoneNumber } from 'util/string';
import { forwardRef, useEffect, useRef } from 'react';
import { PHARMACY_SCHEMA } from 'schema/pharmacy.schema';

export interface PharmacyInfoFormProps {
    initialValues: PrescriptionPharmacyPayload;
}

const PharmacyInfoForm = forwardRef(({ initialValues }: PharmacyInfoFormProps, ref) => {
    const formName = 'PharmacyInfoForm';
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isPharmacySearchFlow = useSelector(pharmacySearchFlowSelector);
    const pharmacyNameInput: any = useRef(null);
    const pharmacyPhoneInput: any = useRef(null);

    useEffect(() => {
        dispatch(setIsPharmacySearchFlow(false));
    }, []);

    const handleSubmitSelectedPharmacy = (values: PrescriptionPharmacyPayload) => {
        pharmacyNameInput.current.value = values.pharmacyName;
        pharmacyPhoneInput.current.value = formatPhoneNumber(values.pharmacyPhone);
        dispatch(savePrescriptionPharmacy(values));
    };

    const clearPrescriptionPharmacyForm = () => {
        const pharmacyPayload: PrescriptionPharmacyPayload = {
            pharmacyName: '',
            pharmacyPhone: '',
            pharmacyAddress: ''
        };
        pharmacyNameInput.current.value = '';
        pharmacyPhoneInput.current.value = '';

        dispatch(savePrescriptionPharmacy(pharmacyPayload));
        ref?.current.resetForm();
    };

    return (
        <Formik<PrescriptionPharmacyPayload>
            innerRef={ref as React.MutableRefObject<FormikProps<PrescriptionPharmacyPayload>>}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleSubmitSelectedPharmacy}
            validationSchema={PHARMACY_SCHEMA(t('countryCode'))}
            initialValues={initialValues}
        >
            {(formik) => (
                <Form
                    className="pharmacy-info-form pt-4 pt-md-3 mt-n5 mt-md-n2"
                    id="pharmacy-info-form"
                    data-ga-form-name={formName}
                    onSubmit={(event) => {
                        handleSubmitSelectedPharmacy(event);
                    }}
                    autoComplete="off"
                >
                    <Row>
                        <Col className="d-flex justify-content-start">
                            <p className="form-instructions mb-1">
                                {t('prescriptionPharmacyForm.pharmacyInformation.title')}
                            </p>
                        </Col>
                    </Row>
                    <Row xs={1}>
                        <Col>
                            <div className="text-slate mt-3 mb-3 font-weight-bolder text-uppercase">
                                {t('prescriptionPharmacyForm.pharmacyInformation.enterDetails')}
                            </div>
                            <Text
                                name="pharmacyName"
                                label={t('prescriptionPharmacyForm.inputs.pharmacyName')}
                                onChange={(event) => {
                                    formik.setFieldValue(event.target.name, event.target.value);
                                }}
                                errors={
                                    formik.errors?.pharmacyName
                                        ? t('forms.errorMessages.requiredField', {
                                              label: t('prescriptionPharmacyForm.inputs.pharmacyName')
                                          })
                                        : undefined
                                }
                                touched={formik.touched.pharmacyName}
                                value={formik.values?.pharmacyName}
                                defaultValue={formik.initialValues.pharmacyName}
                                autocomplete="off"
                                inputRef={pharmacyNameInput}
                            />
                        </Col>
                    </Row>
                    <Row xs={1}>
                        <Col>
                            <PhoneNumberText
                                name="pharmacyPhone"
                                label={t('prescriptionPharmacyForm.pharmacySearch.inputs.phone')}
                                onChange={(event) => {
                                    formik.setFieldValue(event.target.name, event.target.value);
                                }}
                                errors={
                                    formik.errors?.pharmacyPhone
                                        ? t('forms.errorMessages.requiredField', {
                                              label: t('prescriptionPharmacyForm.pharmacySearch.inputs.phone')
                                          })
                                        : undefined
                                }
                                countryCode={t(`countryCode`)}
                                touched={formik.touched.pharmacyPhone}
                                value={formik.values?.pharmacyPhone}
                                defaultValue={formik.initialValues.pharmacyPhone}
                                autocomplete="off"
                                inputRef={pharmacyPhoneInput}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0 pl-sm-3 pr-0 pr-sm-3">
                            <Button
                                variant="link"
                                className="text-options mb-4 cursor-pointer p-0 ml-3 ml-sm-0"
                                onClick={() => {
                                    dispatch(setIsPharmacySearchFlow(!isPharmacySearchFlow));
                                    if (!isPharmacySearchFlow) clearPrescriptionPharmacyForm();
                                }}
                            >
                                {t('prescriptionPharmacyForm.pharmacyInformation.searchYourPharmacy')}
                            </Button>
                            <div className="mt-5 mt-sm-0">
                                {ENABLE_PHARMACY_SEARCH && isPharmacySearchFlow ? (
                                    <PharmacySearchForm
                                        formik={formik}
                                        onSubmitSelectedPharmacy={handleSubmitSelectedPharmacy}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
});

export default PharmacyInfoForm;
